html {
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
    position: fixed;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Disable Text Selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body > div {
    height: 100%;
}

Link, a {
    text-decoration: none
}

.appLoader {
    z-index: 10;
    position: absolute;
}

.footer {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    width: 100%;
}

.backdrop {
    z-index: 100 !important;
}

.scrollContainer {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 50px;
}